import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './OpenACase.css';
import Dialog from './Dialog';
import DialogOk from './DialogOK';
import Attach from './Attach';
import Loading from './Loading';
import { getApiUrl } from './ApiUtil';
import Cookies from 'js-cookie'

export function OpenACase() {

    const apiUrl = getApiUrl();

    const storedTopics = JSON.parse(localStorage.getItem("topicInfo"));
    const storedContactInfo = JSON.parse(localStorage.getItem("contactInfo"));

    const navigate = useNavigate();
    const [internalID, setInternalID] = useState('Loading...');
    const [preferedContactMethod, setPreferedContactMethod] = useState('101');

    const [companyCode, setcompanyCode] = useState("");
    const [testCode, setTestCode] = useState("AAKY");

    const [IntCodes, setintcodes] = useState([]);

    const [prefEmail, setPrefEmail] = useState("Email");
    const [prefPhone, setPrefPhone] = useState("Preferred phone");
    const [prefBusPhone, setPrefBusPhone] = useState("Business phone");
    const [prefEmailactive, setPrefEmailactive] = useState(true);
    const [prefPhoneactive, setPrefPhoneactive] = useState(true);
    const [prefBusPhoneactive, setPrefBusPhoneactive] = useState(true);
    const [defaultMethod, setdefaultMethod] = useState("");
    const [UUID, setUUID] = useState('Loading...');
    const [topicData, setTopicData] = useState([]);
    const [attachData, setAttachData] = useState([]);
    const [attachName, setAttachName] = useState([]);
    const [attachType, setAttachType] = useState([]);
    const [displayloadingDialog, setdisplayloadingDialog] = useState(true);
    const [displaysubmitDialog, setdisplaysubmitDialog] = useState(false);
    const [disablesubmitButton, setdisablesubmitButton] = useState(false);
    const [displaynewCase, setdisplaynewCase] = useState(false);
    const [displayFieldError, setdisplayFieldError] = useState(false);
    const [displaysubmitError, setdisplaysubmitError] = useState(false);

    const [attachmentSize, setattachmentSize] = useState(false);
    const [newID, setNewID] = useState();

    useEffect(() => {
        setdisablesubmitButton(attachmentSize)
        if (attachData === []) {
            setdisablesubmitButton(false);
        }
    }, [attachmentSize, attachData]);

    async function getData() {
        const response = await fetch(`${apiUrl}/emp`, {
            headers: {
                'Authorization': `Bearer ${Cookies.get('access_token')}`
            }
        });
        const data = await response.text();
        return data;
    }
    async function getTopic() {
        const response = await fetch(`${apiUrl}/topic`, {
            headers: {
                'Authorization': `Bearer ${Cookies.get('access_token')}`
            }
        });
        const data = await response.text();
        return data;
    }
    async function getDefaultTopic() {
        const response = await fetch(`${apiUrl}/topic/default`, {
            headers: {
                'Authorization': `Bearer ${Cookies.get('access_token')}`
            }
        });
        const data = await response.text();
        return data;
    }
    async function getCurrentData() {
        const response = await fetch(`${apiUrl}/topic/current`, {
            headers: {
                'Authorization': `Bearer ${Cookies.get('access_token')}`
            }
        });
        return response.text();
    }
    async function getIntCodes() {
        const response = await fetch(`${apiUrl}/topic/intcodes`, {
            headers: {
                'Authorization': `Bearer ${Cookies.get('access_token')}`
            }
        });
        return response.text();
    }
    useEffect(() => {
        let CompanyCode = "";
        async function fetchData() {
            const IntCoderesponse = await getIntCodes();
            setintcodes(IntCoderesponse)
            if (storedContactInfo !== null) {
                setUUID(storedContactInfo.UUID);
                setInternalID(storedContactInfo.InternalID);
                setPreferedContactMethod(storedContactInfo.preferredContactMethod_Employee)
                setcompanyCode(storedContactInfo.CompanyCode);
                CompanyCode = storedContactInfo.CompanyCode;

            } else {

                const response = await getData();

                const xmlDocumentContact = await new DOMParser().parseFromString(response, "text/xml");
                const InternalID = xmlDocumentContact.getElementsByTagName('d:InternalID')[0].textContent;
                const UUID = xmlDocumentContact.getElementsByTagName('d:UUID')[0].textContent;
                const preferredContactMethod_Employee = xmlDocumentContact.getElementsByTagName('d:PreferredContactMethod_Employee')[0].textContent;
                CompanyCode = xmlDocumentContact.getElementsByTagName('d:ZEECompanyCode')[0].textContent;
                if (window.location.href.includes('stage')) {
                    console.log('Company Code: ', CompanyCode);
                    console.log('International Codes', IntCoderesponse);

                }
                setcompanyCode(CompanyCode);
                setPreferedContactMethod(preferredContactMethod_Employee);
                setInternalID(InternalID);
                setUUID(UUID);
            }
            if (storedTopics !== null) {
                
                setTopicData(storedTopics.topicArray);
             
            } else {
                //the data from getCurrentData is a list of the IDs for topics from the most recent version of the catagory catalogue
                //The IDs are compared to the IDs from the getTopic call and any IDs not included in the most recent version are not included in the drop down

                //FIX THIS EVENTUALLY 
                //If the app is running locally, the topic dropdown will be hardcoded to only have Travel. Making a call to the TopicController causes a CORS Error when running locally
                if (window.location.href.includes('localhost')) {
                    const topicName = "Travel";
                    const topicID = 'CSTR';
                    const topic = { topicName, topicID };
                    const topicArray = [];
                    topicArray.push(topic);
                    topicArray.sort((a, b) => a.topicName.localeCompare(b.topicName));
                    setTopicData(topicArray);
                    let topicInfo = { topicArray }
                    localStorage.setItem("topicInfo", JSON.stringify(topicInfo));

                } else {
                    let topicResponse, currentResponse;
                    if (IntCoderesponse.includes(CompanyCode)) {
                        [topicResponse, currentResponse] = await Promise.all([getDefaultTopic(), getCurrentData()]);
                    } else { 
                        [topicResponse, currentResponse] = await Promise.all([getTopic(), getCurrentData()]);
                    }
            let upToDateTopicIDs = [];
            upToDateTopicIDs = JSON.parse(currentResponse);

            const topicXmlDocument = await new DOMParser().parseFromString(topicResponse, "text/xml");
            const entries = topicXmlDocument.querySelectorAll("entry");
            const topicArray = [];
                    const uniqueTopics = new Set()
                    const topicName = "Travel";
                    const topicID = 'CSTR';
                    const topic = { topicName, topicID };
                    topicArray.push(topic);
                    if (CompanyCode === "AACA") {
                        const topicName = "Payroll";
                        const topicID = 'CSPRL';
                        const topic = { topicName, topicID };
                        topicArray.push(topic);
                    }
                    if (IntCoderesponse.includes(CompanyCode)) {
                        for (const entry of entries) {
                            const typeCheck = entry.getElementsByTagName('d:TopicName');
                            if (typeCheck.length > 0) {
                                const topicName = entry.getElementsByTagName('d:TopicName')[0].textContent;
                                const topicID = entry.getElementsByTagName('d:TopicID')[0].textContent;
                                const topic = { topicName, topicID }
                                if (!topicArray.some(existingTopic => existingTopic.topicID === topicID)) {
                                    if (upToDateTopicIDs.includes(topicID) ) {
                                        if (topicName.startsWith("Intl") && topicID.startsWith("CSINT")) {
                                            topicArray.push(topic);
                                            uniqueTopics.add(JSON.stringify(topic));
                                        }
                                    }
                                }
                            }
                        }
                    } else {
                        //eclude int from this for loop
                        for (const entry of entries) {
                            const typeCheck = entry.getElementsByTagName('d:TopicName');
                            if (typeCheck.length > 0) {
                                const topicName = entry.getElementsByTagName('d:TopicName')[0].textContent;
                                const topicID = entry.getElementsByTagName('d:TopicID')[0].textContent;
                                const topic = { topicName, topicID }

                                if (!topicArray.some(existingTopic => existingTopic.topicID === topicID)) {
                                    if (upToDateTopicIDs.includes(topicID)) {
                                        if (topicName.startsWith("Intl") && topicID.startsWith("CSINT")) {

                                        } else {
                                            topicArray.push(topic);
                                            uniqueTopics.add(JSON.stringify(topic));
                                        }
                                    }
                                }
                            }
                        }
                    }


            topicArray.sort((a, b) => a.topicName.localeCompare(b.topicName));
            setTopicData(topicArray);
                let topicInfo = { topicArray }
            localStorage.setItem("topicInfo", JSON.stringify(topicInfo));
                }

            }

            setdisplayloadingDialog(false);
        }
        fetchData();
    },[]);

    const [payload, setPayload] = useState();

    const [displaycancelDialog, setdisplaycancelDialog] = useState(false);
    const confirm = () => {
        
        setdisplaycancelDialog(false);
        window.location.reload();
    };

    const cancel = () => {
        setdisplaycancelDialog(false);
    };
    const ok = () => {
        setdisplaynewCase(false);
        navigate('/my-cases');
    };
    const okError = () => {
        setdisplayFieldError(false);
        setdisplaysubmitError(false);
    }
    const initialValues = { subject: "", topic: "", description: "" };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
        setPayload({
            DataOriginTypeCode: '4',
            Name: { content: formValues.subject, languageCode: "EN" },
            ServicePriorityCode: '7',
            ReportedForPartyID: internalID,
            ReporterPartyID: internalID,
            ServiceIssueCategoryID: formValues.topic,
            PreferredContactMethod: preferedContactMethod,
            ServiceRequestDescription: [{ "Text": formValues.description, "TypeCode": "10004", "AuthorUUID": UUID }]
        });
    };

    const handleSubmit = async (e) =>
    {
        e.preventDefault();
        setFormErrors(validate(formValues));
        setPayload({
            DataOriginTypeCode: '4',
            Name: { content: formValues.subject, languageCode: "EN" },
            ServicePriorityCode: '7',
            ReportedForPartyID: internalID,
            ReporterPartyID: internalID,
            ServiceIssueCategoryID: formValues.topic,
            PreferredContactMethod: preferedContactMethod,
            ServiceRequestDescription: [{ "Text": formValues.description, "TypeCode": "10004", "AuthorUUID": UUID }]
        });
        if (formValues.subject !== "" && formValues.topic !== "" && formValues.description !== "") {
            setdisplaysubmitDialog(true);

            if (attachData !== "") {
                const binary = [];
                for (let i = 0; i < attachData.length; i++) {
                    const payloadAttachment = {
                        Binary: attachData[i],
                        CategoryCode: "2",
                        MimeType: attachType[i],
                        Name: attachName[i],
                        TypeCode: "10001"
                    }
                    binary.push(payloadAttachment)
                }

                const payload =
                {
                    DataOriginTypeCode: '4',
                    Name: { content: formValues.subject, languageCode: "EN" },
                    ServicePriorityCode: '7',
                    ReportedForPartyID: internalID,
                    ReporterPartyID: internalID,
                    ServiceIssueCategoryID: formValues.topic,
                    PreferredContactMethod: preferedContactMethod,
                    ServiceRequestDescription: [{ "Text": formValues.description, "TypeCode": "10004", "AuthorUUID": UUID }],
                    ServiceRequestAttachmentFolder: binary
                }
                try {
                    const response = await fetch(`${apiUrl}/emp/open/attachment`,
                        {
                            method: 'Post',
                            headers:
                            {
                                'Authorization': `Bearer ${Cookies.get('access_token')}`,
                                'Content-Type': 'application/json',
                                'X-CSRF-Token': 'fetch'
                            },
                            body: JSON.stringify(payload)
                        });

                    if (response.ok) {
                        const xmlresponse = await response.text();
                        const xmlDocument = await new DOMParser().parseFromString(xmlresponse, "text/xml");
                        const newID = xmlDocument.getElementsByTagName('d:ID')[0].textContent;
                        setNewID(newID);
                        setdisplaysubmitDialog(false);
                        setdisplaynewCase(true);

                    } else {
                        setdisplaysubmitDialog(false);
                        setdisplaysubmitError(true);
                        const error = await response.text();
                        console.log('POST request failed:', error);
                    }
                } catch (error) {
                    console.log('An error occurred:', error.message);
                }
            }
            else {
                const payload = {
                    DataOriginTypeCode: '4',
                    Name: { content: formValues.subject, languageCode: "EN" },
                    ServicePriorityCode: '7',
                    ReportedForPartyID: internalID,
                    ReporterPartyID: internalID,
                    ServiceIssueCategoryID: formValues.topic,
                    PreferredContactMethod: preferedContactMethod,
                    ServiceRequestDescription: [{ "Text": formValues.description, "TypeCode": "10004", "AuthorUUID": UUID }]
                }
                try {
                    const response = await fetch(`${apiUrl}/emp/open`, {
                        method: 'Post',
                        headers: {
                            'Authorization': `Bearer ${Cookies.get('access_token')}`,
                            'Content-Type': 'application/json',
                            'X-CSRF-Token': 'fetch'
                        },
                        body: JSON.stringify(payload)
                    });

                    if (response.ok) {
                        const xmlresponse = await response.text();
                        const xmlDocument = await new DOMParser().parseFromString(xmlresponse, "text/xml");
                        const newID = xmlDocument.getElementsByTagName('d:ID')[0].textContent;
                        setNewID(newID);
                        setdisplaysubmitDialog(false);
                        setdisplaynewCase(true)
                    } else {
                        setdisplaysubmitDialog(false);
                        setdisplaysubmitError(true);

                        const error = await response.text();
                        console.log('POST request failed:', error);
                    }
                } catch (error) {
                    setdisplaysubmitDialog(false);
                    setdisplaysubmitError(true);

                    console.log('An error occurred:', error.message);
                }
            }
            setIsSubmit(true);
        } else {
            setdisplayFieldError(true);
        }


    };

    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmit) {
        }
    }, [formErrors]
    );

    const validate = (values) => {
        const errors = {};
        if (!values.subject) {
            errors.subject = "Subject is required!";
            window.scrollTo(0, 0);
        }
        if (!values.topic) {
            errors.topic = "Topic is required!";
            window.scrollTo(0, 0);
        }
        if (!values.description) {
            errors.description = "Description is required!";
            window.scrollTo(0, 0);
        }
        return errors;
    };

    const receiveDataFromAttach = (data, files, size) => {
        const parseData = (fileData) => {
            const [type, binary] = fileData.split(';base64,')
            return {
                type: type.split(':')[1],
                binary: binary
            }
        }
        const processedTypes = [];
        const processedBinary = [];
        data.forEach((fileData) => {
            processedTypes.push(parseData(fileData).type)
            processedBinary.push(parseData(fileData).binary)

        });
        setAttachType(processedTypes);
        setAttachData(processedBinary);
        setattachmentSize(!size);
        const fileNames = files.map((file) => file.name)
        setAttachName(fileNames)
    };

    return (
        <div>
            <h1 className="openheader">Open a Case</h1>
            <p><em><small>* Required</small></em> </p>
            <form className="open-case-header" >
                <div className="open-case-item">
                    <label className="text-muted mb-1">
                        <small><span style={{ color: 'red' }}>*</span>Subject:</small>
                    </label>
                    <input name="subject" className="form-control" type="text" value={formValues.subject} onChange={handleChange} />
                </div>
                <small> <p style={{ fontWeight: 'bold' }}> {formErrors.subject}</p></small>
                <div className="open-case-item">
                    <label className="text-muted mb-1">
                        <small><span style={{ color: 'red' }}>*</span>Topic:</small>
                    </label>
                    <select name="topic" className="form-control custom-select" type="text" value={formValues.topic} onChange={handleChange}>
                        <option value="">Select a Topic</option>
                        {topicData.map((topic) => (
                            <option key={topic.topicID} value={topic.topicID}>
                                {topic.topicName}
                            </option>
                        ))}
                    </select>
                </div>
                <small> <p style={{ fontWeight: 'bold' }}> {formErrors.topic}</p></small>
                <div className="open-case-item">
                    <label className="text-muted mb-1">
                        <small><span style={{ color: 'red' }}>*</span>Description:</small>
                    </label>
                    <textarea rows={4} id="description" name="description" className="form-control" type="text" value={formValues.description} onChange={handleChange} />
                </div>
                <small className="text-muted mb-1">Do not upload Personal Identifiable Information (PII).</small>
                <small><p style={{ fontWeight: 'bold' }}> {formErrors.description}</p></small>
            </form>
            <div className="attachmentForm">
                <Attach sendDataToParent={receiveDataFromAttach} caseCanceled={false} />
            </div>
            <div className="submit-buttons">
                <button onClick={() => { setdisplaycancelDialog(true) }} className="cancel-button" type="reset">Cancel</button>
                <button onClick={handleSubmit} className="save-button" disabled={disablesubmitButton}>Submit</button>
            </div>


            <Dialog
                display={displaycancelDialog}
                title="Changes Not Saved"
                confirm={confirm}
                cancel={cancel}
                description="Your changes have not been saved. Are you sure you want to cancel?"
            />
            <Loading
                display={displayloadingDialog}
                title="Loading..."
                description="Your case options are loading"
            />
            <Loading
                display={displaysubmitDialog}
                title="Submitting New Case..."
                description="Your case is being opened, this could take a couple seconds"
            />
            <DialogOk
                display={displaynewCase}
                title="Case Submitted"
                ok={ok}
                description={`Thank you for opening case ${newID}. Please check back here for the response to your inquiry.`}
                color="green"
            />
            <DialogOk
                display={displayFieldError}
                title="Missing Field"
                ok={okError}
                description={`A Subject, Topic, and Description are require to open a new case.`}
                color="red"
            />
            <DialogOk
                display={displaysubmitError}
                title="Submit Error"
                ok={okError}
                description={`There was an error submitting your case, please try again later`}
                color="red"
            />
        </div>
    );
};

export default OpenACase;