import React, { useState, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { Layout } from './components/Layout';
import './custom.css';
import { Callback } from './components/Authorization/Callback';
import Cookies from 'js-cookie'
import Maintenance from './Maintenance';

//delare variables used to authenticate in either dev, stage, or prod environments
const authorizeUrlD = process.env.REACT_APP_AUTH_URL;
const clientIdD = process.env.REACT_APP_IDENTITY_CLIENT_ID;
const redirectUriD = process.env.REACT_APP_REDIRECT_URL + '/callback';

const authorizeUrlS = process.env.REACT_APP_STAGING_AUTH_URL;
const clientIdS = process.env.REACT_APP_STAGING_IDENTITY_CLIENT_ID;
const redirectUriS = process.env.REACT_APP_STAGING_REDIRECT_URL + '/callback';

const authorizeUrlP = process.env.REACT_APP_PRODUCTION_AUTH_URL;
const clientIdP = process.env.REACT_APP_PRODUCTION_IDENTITY_CLIENT_ID;
const redirectUriP = process.env.REACT_APP_PRODUCTION_REDIRECT_URL + '/callback';

const enhanceAuthorizeUrl = (authorizeUrl, clientId, redirectUri) => {
    return `${authorizeUrl}?response_type=code&client_id=${clientId}&scope=openid&redirect_uri=${redirectUri}`;
};

const App = () => {

    const [loading, setLoading] = useState(true);
    //SSCM uses cookies to store information. If the user doesn't have cookies enabled it can cause issues
    //Information being stored using js-cookie is: the starting url for rediretion, the access token returned from single sign on, and the id token returned from single sign on used for logging out.
    //You can look at the cookies being used if you log in to SSCM, open the browser console, and enter 'document.cookie'. This is useful for manually getting an access token for testing
    useEffect(() => {
        const login = () => {
            //checks if the user has already logged in and gotten an access token from Callback.js, if it hasn't it sends the user to the callback endpoint
            if (
                (Cookies.get('access_token') === 'undefined' ||
                    Cookies.get('access_token') === undefined) &&
                window.location.href.split('/')[3].substring(0, 14) !== 'callback?code='
            ) {
                Cookies.set('url', window.location.href.split('/')[3], {});
                //checks the domain to know which environment values to use
                //should be reworked to automatically detect environment the code is in without checking the domain name
                if (window.location.origin.includes("localhost")) {
                    window.location.replace(
                        enhanceAuthorizeUrl(authorizeUrlD, clientIdD, redirectUriD)
                    );
                } else {

                    if (window.location.origin.includes('stage')) {
                        window.location.replace(
                            enhanceAuthorizeUrl(authorizeUrlS, clientIdS, redirectUriS)
                        );
                    }
                    else {
                        window.location.replace(
                            enhanceAuthorizeUrl(authorizeUrlP, clientIdP, redirectUriP)
                        );
                    }
                }


                //once the user has logged in and an access token has been saved to the cookies, the load screen is disabled
            } else {
                setLoading(false);
                fetch('message/', {
                    headers: { Authorization: `Bearer ${Cookies.get('access_token')}` },
                })
                    .then((response) => {
                        if (!response.ok) {
                            console.log('Token failed to validate.');
                            setLoading(false);
                            login();
                        } else {
                            setLoading(false);
                        }
                    })
                    .catch((error) => {
                        console.log('error', error);
                        login();
                    });
            }
        };

        login();
    }, []);

    useEffect(() => {
        const handleBeforeUnload = () => {
            //Clear local storage when the user leaves the page
            localStorage.removeItem("contactInfo")
            localStorage.removeItem("topicInfo")
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    //displays a loading screen before sending you to SSO
    if (loading) {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                    fontSize: '48px', 
                }}
            >
                Loading Case Manager...
            </div>
        );
    }

    //prevents unathenticated users from accessing any route except callback
    if (Cookies.get('access_token') !== 'undefined' && Cookies.get('access_token') !== undefined && !loading) {
        //sends the user to the maitenance page if the app is in maintenance mode
        if (Cookies.get('maintenance') === 'true') {
            return (
                <Maintenance />
            )
        } else {
            //the full webpage is exported here
            return (
                <Layout>
                    <Routes>
                        <Route path="/callback" element={<Callback />} />
                        {AppRoutes.map((route, index) => {
                            const { element, ...rest } = route;
                            return <Route key={index} {...rest} element={element} />;
                        })}
                    </Routes>
                </Layout>
            );
        }
    } else {
        return (
                <Routes>
                    <Route path="/callback" element={<Callback />} />
                </Routes>
        );
    }
};

export default App;

